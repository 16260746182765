<template>
  <div class="table-responsive">
    <table class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>Paid On</th>
          <th>Transition ID</th>
          <th>Amount</th>
          <th>Invoice #</th>
        </tr>
      </thead>
      <tbody v-if="items && !!items.length">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>{{ item.paid_on }}</td>
          <td>{{ item.transitionId }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.id }}</td>
          <!--          <td>{{ moment(item.created_on).format('YYYY-MM-DD')}}</td>-->
        </tr>
      </tbody>
      <tbody v-else>
        <td
          colspan="6"
          class="text-center font-weight-bold py-4"
        >
          <h4 class="text-secondary">
            NO VALUES FOUND
          </h4>
        </td>
      </tbody>
    </table>
    <a-pagination
      v-if="total"
      class="pagination-right"
      :default-current="1"
      :total="total"
      @change="pagination($event)"
    />
  </div>
</template>

<script>
    import axios from '@/axios'
    import moment from 'moment'
    import {Modal} from 'ant-design-vue'

    export default {
        data() {
            return {
                moment,
                step: 10,
                skip: 0,
                total: null,
                items: null,
                notFound: false
            }
        },
        mounted() {
            this.loadItems()
        },
        methods: {
            pagination(int) {
                this.skip = 0
                this.skip += this.step * int - this.step
                this.loadItems()
            },
            loadItems() {
                axios
                    .get(`/user/my-invoices?order_dir=ASC&order_by=created_on&take=10&skip=${this.skip}`)
                    .then((res) => {
                        this.items = res.data.data
                        if (Object.keys(this.items).length === 0) {
                            this.notFound = false
                        } else {
                            this.notFound = true
                        }
                        this.total = res.data.count > this.step ? res.data.count : null
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            // approveConfirm(id) {
            //   let vm = this
            //   Modal.confirm({
            //     title: 'Do you want to approve this application?',
            //     onOk() {
            //       vm.approve(id)
            //     },
            //     class: 'test',
            //   })
            // },
            // rejectConfirm(id) {
            //   let vm = this
            //   Modal.confirm({
            //     title: 'Do you want to reject this application?',
            //     content: (
            //       <div class="d-flex flex-column" width="100%">
            //         <span>Enter the reason:</span>
            //         <input width="100%" id="input" />
            //       </div>
            //     ),
            //     onOk() {
            //       const reason = document.querySelector('#input').value
            //       vm.reject(id, reason)
            //     },
            //     class: 'test',
            //   })
            // },
            // approve(id) {
            //   axios
            //     .post(`registration/${id}/approve`)
            //     .then((res) => {
            //       console.log(res)
            //       this.success()
            //     })
            //     .catch((err) => {
            //       console.log(err)
            //       this.danger()
            //     })
            // },
            // reject(id, reason) {
            //   axios
            //     .post(`registration/${id}/reject`, {
            //       reason,
            //     })
            //     .then(() => {
            //       this.reason = null
            //       this.success()
            //     })
            //     .catch((err) => {
            //       this.reason = null
            //       console.log(err)
            //       this.danger()
            //     })
            // },
            // success() {
            //   const vm = this
            //   Modal.success({
            //     title: 'Operation completed successfully',
            //     onOk() {
            //       vm.loadItems()
            //     },
            //   })
            // },
            // danger() {
            //   Modal.error({
            //     title: 'Something went wrong, please update and try again',
            //   })
            // },
        },
    }
</script>

<style scoped>
    .pagination-right {
        text-align: right !important;
        margin-right: 86px !important;
    }
</style>